/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { ref, computed } from '@vue/composition-api'
import { parsePhoneNumber, AsYouType } from 'libphonenumber-js'
import { PHONE_CODES } from './constans'

export default function usePhone() {
  const phoneNumber = ref('')
  const phoneCode = ref('')
  const fullPhone = ref(null)
  const fullPhones = ref([])

  const phoneCodeOptions = computed(() => PHONE_CODES)

  const validatePhone = (e, dataPhone) => {
    if (phoneCode.value && e) {
      const phoneNumberJS = parsePhoneNumber(e, phoneCode.value)
      if (phoneNumberJS) {
        if (phoneNumberJS.isValid()) {
          dataPhone = phoneNumberJS.number
          fullPhone.value = phoneNumberJS.number

          return true
        }
      }
    }

    return 'Invalid number'
  }

  const validatePhones = (e, phoneInput) => {
    if (phoneInput.code && e && e.length > 3) {
      const phoneNumberJS = parsePhoneNumber(e, phoneInput.code)
      if (phoneNumberJS) {
        if (phoneNumberJS.isValid()) {
          phoneInput.phone = phoneNumberJS.number
          fullPhone.value = phoneNumberJS.number

          return true
        }
      }
    }

    if (e === '' || e === null) return true

    return 'Invalid number'
  }

  const initPhoneConfig = phone => {
    const phoneNumberInit = parsePhoneNumber(phone)
    const config = {
      phone_number: phoneNumberInit.nationalNumber || '',
      code: phoneNumberInit.country || '',
    }

    return config
  }

  const formatNumber = phone => new AsYouType().input(phone)

  return {
    // data
    phoneNumber,
    phoneCode,
    fullPhone,
    fullPhones,

    // computed
    phoneCodeOptions,

    // methods
    validatePhone,
    validatePhones,
    initPhoneConfig,
    formatNumber,
  }
}
